import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './assets/css/global.css'
import './assets/font/iconfont.css'
import './assets/icon/iconfont.css'
import fromCommon from "./utils/formValidation" 
import axios from 'axios'
import * as echarts from 'echarts';
// 后端存储Cookie，这句话直接获取到Cookie了
axios.defaults.withCredentials=true;//让ajax携带cookie
Vue.prototype.$echarts = echarts;  //全局挂载echarts
Vue.prototype.$fromCommon = fromCommon;  //全局挂载封装正则
Vue.config.productionTip = false
axios.defaults.baseURL = 'https://gglm.qhja.top/'
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

