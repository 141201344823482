import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'
const Home = () => import(  '../views/Home.vue')
const Login = () => import( '../views/Login.vue')

const BasicSetting = () => import(  '../views/basics/BasicSetting.vue')
// 会员列表
const MemberUser = () => import(  '../views/member/MemberUser.vue')
// 客服管理
const Service = () => import(  '../views/member/Service.vue')
// 商业管理
const Business = () => import(  '../views/member/Business.vue')
// 广告商
const Advertiser = () => import(  '../views/member/Advertiser.vue')
// 计划列表
const PlanManagement = () => import(  '../views/plan/PlanManagement.vue')
// 申请投放审核
const Apply = () => import(  '../views/plan/Apply.vue')
// 广告管理
const Advertising = () => import(  '../views/advertising/Advertising.vue')
// 新增广告
const NewAdvertising = () => import('../views/advertising/NewAdvertising.vue')
// 网站管理
const Website = () => import( '../views/website/Website.vue')
// 新增网站
const Newweb = () => import( '../views/website/newweb.vue')
// 广告位管理
const AdvertisingSpace = () => import( '../views/website/AdvertisingSpace.vue')
// 数据报表
const DataReport = () => import('../views/datareport/DataReport.vue')
// 导入数据
const Orderlist = () => import(  '../views/datareport/Orderlist.vue')
// 搜索引擎
const Trendlist = () => import(  '../views/trend/trendlist.vue')
// 趋势分析
const Search = () => import( '../views/trend/search.vue')
// 操作系统
const OS = () => import( '../views/trend/os.vue')
// 公告内容
const AfficheList = () => import(  '../views/affiche/AfficheList.vue')
// 最新内容
const Activity = () => import(  '../views/affiche/activity.vue')
// 站长帮助
const Help = () => import(  '../views/affiche/help.vue')
// 广告商帮助
const Advertisers = () => import(  '../views/affiche/advertiser.vue')
// 添加公告
const AfficheAdd = () => import(  '../views/affiche/Afficheadd.vue')
// 广告类型
const AdvType = () => import(  '../views/advtemplate/advType.vue')
// 广告模式
const AdvPattern = () => import(  '../views/advtemplate/advPattern.vue')
// 广告样式
const AdvStyle = () => import(  '../views/advtemplate/advStyle.vue')
// 广告尺寸
const AdvSize = () => import(  '../views/advtemplate/advSize.vue')
// 广告管理员
const Admin = ()=>import('../views/other/Admin.vue')
// 角色管理
const Rolelist = ()=>import('../views/other/rolelist.vue')
// 登录日志
const AdminLog = ()=>import('../views/other/adminLog.vue')
// 消息管理
const Message = ()=>import('../views/other/Message.vue')
// 网站分类
const Web = ()=>import('../views/other/web.vue')
// 网站分类
const Onlinepay = ()=>import('../views/money/onlinepay.vue')
// 财务结算
const SettlementList = ()=>import('../views/money/settlementList.vue')
Vue.use(VueRouter)
const routes = [
  { path: '/',  redirect: '/login', },
  { path: '/login', component: Login,  },
  {
  path: '/home',
  component: Home,
  name: 'Home',
  redirect: '/basicsetting',
  children: [
    {
      // 基础设置
      path: '/basicsetting',
      component: BasicSetting,
      name: 'BasicSetting'
    },
    {
      // 会员管理
      path: '/memberUser',
      component: MemberUser,
      name: 'MemberUser'
    },
    {
      // 客服管理
      path: '/service',
      component: Service,
      name: 'Service'
    },
    {
      // 广告商管理
      path: '/advertiser',
      component: Advertiser,
      name: 'Advertiser'
    },
    {
      // 商务管理
      path: '/business',
      component: Business,
      name: 'Business'
    },
    {
      // 计划列表
      path: '/planmanagement',
      component: PlanManagement,
      name: 'PlanManagement'
    },
    
    {
      // 申请投放审核
      path: '/apply',
      component: Apply,
      name: 'Apply'
    },
    {
      // 所有广告列表
      path: '/advertising',
      component: Advertising,
      name: 'Advertising'
    },
    {
      // 新建广告
      path: '/newadvertising',
      component: NewAdvertising,
      name: 'NewAdvertising'
    },
    {
      // 网站管理
      path: '/website',
      component: Website,
      name: 'Website'
    },
    {
      // 新增网站
      path: '/newweb',
      component: Newweb,
      name: 'Newweb'
    },
    {
      // 广告位管理
      path: '/advertisingspace',
      component: AdvertisingSpace,
      name: 'AdvertisingSpace'
    },

    {
      // 数据报表
      path: '/dataReport',
      component: DataReport,
      name: 'DataReport'
    },
    {
      // 导入数据
      path: '/orderlist',
      component: Orderlist,
      name: 'Orderlist'
    },
    {
      // 搜索引擎
      path: '/trendlist',
      component: Trendlist,
      name: 'Trendlist'
    },
    {
      //趋势分析
      path: '/search',
      component: Search,
      name: 'Search'
    },
    {
      //操作系统
      path: '/os',
      component: OS,
      name: 'OS'
    },
    {
      //公告内容
      path: '/afficheList',
      component: AfficheList,
      name: 'AfficheList'
    },
    {
      //最新内容
      path: '/activity',
      component: Activity,
      name: 'Activity'
    },
    {
      //站长帮助
      path: '/help',
      component: Help,
      name: 'Help'
    },
    {
      //广告商帮助
      path: '/advertisers',
      component: Advertisers,
      name: 'Advertisers'
    },
    {
      //添加公告
      path: '/afficheAdd',
      component: AfficheAdd,
      name: 'AfficheAdd'
    },
    {
      //广告类型
      path: '/advType',
      component: AdvType,
      name: 'AdvType'
    },
    {
      //广告模式
      path: '/advPattern',
      component: AdvPattern,
      name: 'AdvPattern'
    },
    {
      //广告样式
      path: '/advStyle',
      component: AdvStyle,
      name: 'AdvStyle'
    },
    {
      //广告尺寸
      path: '/advSize',
      component: AdvSize,
      name: 'AdvSize'
    },
    
    {
      //管理员管理
      path: '/admin',
      component: Admin,
      name: 'Admin'
    }, 
    {
      //角色管理
      path: '/rolelist',
      component: Rolelist,
      name: 'Rolelist'
    },{
      //消息管理
      path: '/message',
      component: Message,
      name: 'Message'
    }, {
      //网站分类
      path: '/web',
      component: Web,
      name: 'Web'
    }, 
    {
      //登录日志
      path: '/adminLog',
      component: AdminLog,
      name: 'AdminLog'
    }, 
    {
      //充值列表
      path: '/onlinepay',
      component: Onlinepay,
      name: 'Onlinepay'
    }, 
    {
      //财务结算
      path: '/settlementList',
      component: SettlementList,
      name: 'SettlementList'
    },
  ]
}]
const router = new VueRouter({
  routes
})
//挂载路由导航守卫
router.beforeEach((to, from, next) =>{
  // 如果用户访问的登录页，直接放行
  if (to.path == '/login') return next()
  const tokenStr = Cookies.get('loginSate')
  //没有 token，强制跳转到登录页
  if (!Number(tokenStr)) {
    // window.alert("请先登录！")
    return next('/login')
  }
  next()
})

export default router