let mobile = /^1[3456789]\d{9}$/; //手机号码
let number = /^(([1-9]{1}\d*)|(0{1}))(\.\d{2})$/; //必须为数字＋小数点后2位
let name =  /^[A-Za-z0-9\-_]+[A-Za-z0-9\.\-_]*[A-Za-z0-9\-_]+@[A-Za-z0-9]+[A-Za-z0-9\.\-_]*(\.[A-Za-z0-9\.\-_]+)*[A-Za-z0-9]+\.[A-Za-z0-9]+[A-Za-z0-9\.\-_]*[A-Za-z0-9]+$/ ; //用户名
let password = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/; //密码
let qq = /^[1-9][0-9]{4,10}$/; //qq
let num =/^[0-9]*$/ //数字
let FormValidate = (function() {
//  校验必须为正确手机号码
  function FormValidate() {}
  FormValidate.FormMobile = function() { 
    return {
      // 电话号码的验证
      Mobile: (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请输入手机号"));
        } else {
          if (!mobile.test(value)) {
            callback(new Error("请输入正确的手机号"));
          } else {
            callback();
          }
        }
      },
    }
  }
  //校验必须为数字
  FormValidate.FormNumber = function() {
    return {
      // 姓名的验证规则
      Number: function(rule, value, callback) {
        if (value === "") {
          callback(new Error("此项不能为空！"));
        } else {
          if (!number.test(value)) {
            callback(new Error("请输入精确到后2位正确的数字!"));
          } else {
            callback();
          }
        }
      }
    }
  }
  // 校验用户名
   FormValidate.FormName = function() {
    return {
      // 姓名的验证规则
      Name: function(rule, value, callback) {
        if (value === "") {
          callback(new Error("此项不能为空！"));
        } else {
          if (!name.test(value)) {
            callback(new Error("请填写正确的邮箱地址"));
          } else {
            callback();
          }
        }
      }
    }
  }
  // 校验密码
  FormValidate.FormPassword = function() {
    return {
      // 姓名的验证规则
      Password: function(rule, value, callback) {
        if (value === "") {
          callback(new Error("此项不能为空！"));
        } else {
          if (!password.test(value)) {
            callback(new Error("密码需要6-16位英文和数字组合"));
          } else {
            callback();
          }
        }
      }
    }
  }
   // 校验QQ
   FormValidate.FormQq= function() {
    return {
      // 姓名的验证规则
      Qq: function(rule, value, callback) {
        if (value === "") {
          callback(new Error("此项不能为空！"));
        } else {
          if (!qq.test(value)) {
            callback(new Error("必须包含一个大写,一个小写字母,且长度为8到16位"));
          } else {
            callback();
          }
        }
      }
    }
  }
   // 校验数字0-9
   FormValidate.FormNum= function() {
    return {
      // 姓名的验证规则
      Num: function(rule, value, callback) {
        if (value === "") {
          callback(new Error("此项不能为空！"));
        } else {
          if (!num.test(value)) {
            callback(new Error("请输入长度为8到16位数字"));
          } else {
            callback();
          }
        }
      }
    }
  }
  return FormValidate
}())
exports.FormValidate = FormValidate


